import config from 'config'
import { prepareDomain } from 'utils/prepare'

/* eslint-disable complexity */
const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAYS_IN_MONTH = 31
const MONTH =
  DAYS_IN_MONTH *
  HOURS_IN_DAY *
  MINUTES_IN_HOUR *
  SECONDS_IN_MINUTE *
  MILLISECONDS_IN_SECOND

export const initContractor = ({ cookie }) => {
  const toolbox = {
    cookie
  }

  const domain = SERVER ? prepareDomain(config.domain) : window.location.hostname

  const getContractorId = (userId = 0) => {
    const contractorId = toolbox.cookie.load('contractorId', { path: '/', domain })
    const contractorUserId = toolbox.cookie.load(`contractorId_${userId}`, {
      path: '/',
      domain
    })
    return contractorId === '-1' ? undefined : contractorUserId || contractorId
  }

  const setContractorId = (id, userId = 0) => {
    // 1 месяц
    const expires = new Date(Date.now() + MONTH)
    if (id && Number(id) !== -1) {
      toolbox.cookie.save('contractorId', id, { path: '/', expires, domain })
      toolbox.cookie.save(`contractorId_${userId}`, id, { path: '/', expires, domain })
    } else if (id && Number(id) === -1) {
      toolbox.cookie.remove('contractorId', { path: '/', expires, domain })
      toolbox.cookie.remove(`contractorId_${userId}`, { path: '/', expires, domain })
    }
    return getContractorId(userId)
  }

  const getGeneralContractorId = () => {
    const contractorId = toolbox.cookie.load('generalContractorId', {
      path: '/',
      domain
    })
    return contractorId === '-1' ? undefined : contractorId
  }
  const setGeneralContractorId = id => {
    const expires = new Date(Date.now() + MONTH)
    if (id && Number(id) !== -1) {
      toolbox.cookie.save('generalContractorId', id, { path: '/', expires, domain })
    } else {
      toolbox.cookie.remove('generalContractorId', { path: '/', expires, domain })
    }
    return getContractorId()
  }

  const clearContractorId = (userId = 0) => {
    // sessionStorage.clear()
    toolbox.cookie.remove('contractorId', { path: '/', domain })
    if (userId) {
      toolbox.cookie.remove(`contractorId_${userId}`, { path: '/', domain })
    }
  }

  return {
    getContractorId,
    setContractorId,
    getGeneralContractorId,
    setGeneralContractorId,
    clearContractorId
  }
}
