// @ts-nocheck
import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

import getProducts from 'helpers/getProducts'

// todo: fix
// todo: типизация в конфиге вебпака для tsx loadable
const CatalogPurchased = loadable(() =>
  import(
    /* webpackChunkName: "CatalogPurchased" */ 'pages/CatalogPurchased/CatalogPurchased'
  )
)

export default asyncConnect([
  {
    key: 'pageCatalogPurchased',
    promise: (props: { [key: string]: any, store: object, location: object, helpers: object }) =>
      getProducts({
        url: 'catalog/personal',
        type: 'purchased'
      })(props)
  }
])(CatalogPurchased)
