/* eslint-disable complexity */
import { prop, propOr } from 'ramda'

interface IMenuItem {
  ID: string;
  IS_LAST?: boolean;
}

interface IGetMenuLink {
  isBookmark?: boolean;
  bookmark?: number;
  item: IMenuItem
}

interface ICatalogItem {
  ID: string;
  CODE?: string;
}

interface IGetCatalogLinkParams {
  isBookmark?: boolean;
  bookmark?: number;
  item: ICatalogItem;
}

export const getMenuLink = ({ isBookmark = false, bookmark = 0, item }: IGetMenuLink): string => {
  const isLast: boolean = propOr(false, 'IS_LAST', item)
  if (isBookmark) {
    return isLast
      ? `/bookmark/${bookmark}/catalog/products/${item.ID}`
      : `/bookmark/${bookmark}/catalog/sections/${item.ID}`
  }
  if (isLast) {
    return `/catalog/products/${item.ID}`
  }
  return `/catalog/sections/${item.ID}`
}

export const getCatalogLink = ({ isBookmark = false, bookmark = 0, item }: IGetCatalogLinkParams): string =>
  isBookmark
    ? `/bookmark/${bookmark}/catalog/products/${prop('ID', item)}`
    : `/catalog/products/${prop('ID', item)}`
