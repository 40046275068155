/* eslint-disable complexity */
/* eslint-disable camelcase */
import axios from 'axios'
import qs from 'qs'
import { pathOr, has, once } from 'ramda'

import config from 'config'
import { initContractor } from 'helpers/contractorNew'
import { prepareDomain } from 'utils/prepare'

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAY_IN_MONTH = 31
const MONTH =
  DAY_IN_MONTH *
  HOURS_IN_DAY *
  MINUTES_IN_HOUR *
  SECONDS_IN_MINUTE *
  MILLISECONDS_IN_SECOND
const NUMBER_20 = 20
const HOURS_20 = NUMBER_20 * MINUTES_IN_HOUR * SECONDS_IN_MINUTE // приходит в секундах с сервера

export const init = ({ cookie, apiAuth, router }) => {
  let fetchingToken = false
  const contractorUtils = initContractor({ cookie })
  const toolbox = { cookie }

  const locationReload = () =>
    typeof window !== 'undefined' && window.location.reload(true)

  const fetchToken = data => {
    const { change_client, credentials } = SERVER ? config.apiAuth : apiAuth
    const query = pathOr({}, ['location', 'query'], router)
    const hasQueryParams =
      change_client && has('client_id', query) && has('client_secret', query)
    const { client_id, client_secret } = hasQueryParams ? query : credentials
    const params = { ...data, client_id, client_secret }

    return axios.post('/oauth/token/', qs.stringify(params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true,
      baseURL: SERVER ? config.authServer : undefined
    })
  }

  const domain = SERVER ? prepareDomain(config.domain) : window.location.hostname

  const getExpiresTime = (expiresIn = HOURS_20, type = 'access') =>
    type === 'access'
      ? expiresIn * MILLISECONDS_IN_SECOND + Date.now()
      : expiresIn + MONTH + Date.now()

  const getTokenFromCookies = () => {
    const expires = toolbox.cookie.load('expires', { path: '/', domain })
    const refreshToken = toolbox.cookie.load('refreshToken', { path: '/', domain })
    const accessToken = toolbox.cookie.load('accessToken', { path: '/', domain })
    const expiresIn = toolbox.cookie.load('expiresIn', { path: '/', domain })

    return {
      accessToken,
      refreshToken,
      expires,
      expiresIn,
      isUserWithoutPassword: Boolean(!refreshToken)
    }
  }

  const saveToCookies = tokens => {
    const expires = new Date(getExpiresTime(tokens.expires_in))
    if (tokens.access_token && tokens.access_token !== 'undefined') {
      toolbox.cookie.save('accessToken', tokens.access_token, {
        path: '/',
        expires,
        domain
      })
      toolbox.cookie.save('expiresIn', tokens.expires_in, {
        path: '/',
        expires,
        domain
      })
      toolbox.cookie.save('expires', getExpiresTime(tokens.expires_in), {
        path: '/',
        expires,
        domain
      })

      if (tokens.refresh_token) {
        toolbox.cookie.save('refreshToken', tokens.refresh_token, {
          path: '/',
          expires: new Date(getExpiresTime(tokens.expires_in, 'refresh')),
          domain
        })
      }
    }
    return {
      accessToken: tokens.access_token,
      refreshToken: tokens.refresh_token ? tokens.refresh_token : undefined,
      expires,
      expiresIn: tokens.expires_in,
      isUserWithoutPassword: Boolean(!tokens.refresh_token)
    }
  }

  const getAdminTokenFromCookies = () => {
    const expires = toolbox.cookie.load('superuserExpires', { path: '/', domain })
    const refreshToken = toolbox.cookie.load('superuserRefreshToken', {
      path: '/', domain
    })
    const accessToken = toolbox.cookie.load('superuserAccessToken', {
      path: '/', domain
    })
    const expiresIn = toolbox.cookie.load('superuserExpiresIn', { path: '/', domain })

    return {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires,
      expires_in: expiresIn
    }
  }

  const clearAdminToken = () => {
    const cookiesToClear = [
      'superuserAccessToken',
      'superuserExpiresIn',
      'superuserExpires',
      'superuserRefreshToken'
    ]
    cookiesToClear.forEach(name => toolbox.cookie.remove(name, { path: '/', domain }))
  }

  const getIsAdminCookie = () =>
    Boolean(toolbox.cookie.load('superuserRefreshToken', { path: '/', domain }))

  const saveToAdminCookies = tokens => {
    const expires = new Date(getExpiresTime(tokens.expiresIn))
    if (tokens.accessToken && tokens.accessToken !== 'undefined') {
      toolbox.cookie.save('superuserAccessToken', tokens.accessToken, {
        path: '/',
        expires,
        domain
      })
      toolbox.cookie.save('superuserExpiresIn', tokens.expiresIn, {
        path: '/',
        expires,
        domain
      })
      toolbox.cookie.save(
        'superuserExpires',
        getExpiresTime(tokens.expiresIn),
        { path: '/', expires, domain }
      )
      if (tokens.refreshToken) {
        toolbox.cookie.save('superuserRefreshToken', tokens.refreshToken, {
          path: '/',
          expires: new Date(getExpiresTime(tokens.expiresIn, 'refresh')),
          domain
        })
      }
    }
    return {
      superuserAccessToken: tokens.accessToken,
      superuserRefreshToken: tokens.refreshToken
        ? tokens.refreshToken
        : undefined,
      superuserExpires: getExpiresTime(tokens.expiresIn),
      superuserExpiresIn: tokens.expiresIn
    }
  }

  const clearToken = () => {
    const cookiesToClear = [
      'accessToken',
      'PHPSESSID',
      'expiresIn',
      'expires',
      'refreshToken',
      'params_catalog_filter'
    ]

    contractorUtils.clearContractorId()
    cookiesToClear.forEach(name =>
      toolbox.cookie.remove(name, { path: '/', domain })
    )
  }

  const saveAndResolveToken = response => {
    const tokens = response.data.response
    return saveToCookies(tokens)
  }

  const getTokenWithoutPassword = () =>
    fetchToken({
      grant_type: 'client_credentials'
    })
      .then(saveAndResolveToken)
      .catch(() => {
        throw new Error('Fetch token failure')
      })

  const handleTokenFailed = () => {
    clearToken()
    return getTokenWithoutPassword()
  }

  const handlePasswordFailed = data => {
    throw data
  }

  const getTokenByPassword = ({ username, password }) =>
    fetchToken({
      grant_type: 'password',
      username,
      password
    })
      .then(saveAndResolveToken)
      .catch(handlePasswordFailed)

  const getTokenByRefresh = () =>
    fetchToken({
      grant_type: 'refresh_token',
      refresh_token: getTokenFromCookies().refreshToken
    })
      .then(saveAndResolveToken)
      .catch(handleTokenFailed)

  const isTokenExists = () => Boolean(getTokenFromCookies().accessToken)

  const isTokenExpired = () =>
    Boolean(
      !getTokenFromCookies().expires ||
        !isTokenExists() ||
        Number(getTokenFromCookies().expires) < Date.now()
    )

  const isRefreshTokenExists = () => Boolean(getTokenFromCookies().refreshToken)

  const getTokenBefore = () => {
    if (fetchingToken) return fetchingToken
    if (!isTokenExpired()) {
      const tokens = getTokenFromCookies()
      return Promise.resolve(tokens)
    }
    if (isTokenExpired() && isRefreshTokenExists()) {
      fetchingToken = getTokenByRefresh().then(data => {
        fetchingToken = false
        return Promise.resolve(data)
      })
      return fetchingToken
    }
    fetchingToken = getTokenWithoutPassword().then(data => {
      fetchingToken = false
      return Promise.resolve(data)
    })
    return fetchingToken
  }

  const getNewToken = once(() => {
    fetchToken({
      grant_type: 'refresh_token',
      refresh_token: getTokenFromCookies().refreshToken
    })
      .then(saveAndResolveToken)
      .then(locationReload)
      .catch(handleTokenFailed)
      .then(locationReload)
  })

  return {
    getTokenFromCookies,
    clearToken,
    clearAdminToken,
    getTokenByPassword,
    isTokenExpired,
    saveToCookies,
    getAdminTokenFromCookies,
    saveToAdminCookies,
    getIsAdminCookie,
    getNewToken,
    getTokenBefore
  }
}
