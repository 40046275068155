/* eslint-disable complexity */
import React, {
  useState, useEffect, useRef, useCallback, memo, ReactNode, FC, MouseEvent
} from 'react'

import cx from 'classnames'
import { pathOr } from 'ramda'
import { useKeyPressEvent } from 'react-use'

import Icon from 'components/Icon'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import { hideModal } from 'redux/modules/modal'

import s from './Modal.scss'
import { ModalSizeProps, ModalBgProps } from './types'

const POSITION_100 = 100

interface IModalProps {
  children: ReactNode,
  dispatch: (arg: any) => void,
  title?: string | ReactNode,
  isCloseButton?: boolean,
  onClose?: () => void,
  onOpen?: () => void,
  preventDefaultClose?: boolean,
  size?: ModalSizeProps,
  background?: ModalBgProps
}

const Modal: FC<IModalProps> = ({
  children,
  dispatch = () => {},
  title,
  isCloseButton = true,
  onClose = () => {},
  onOpen = () => {},
  preventDefaultClose = false,
  size = 'default',
  background = 'white'
}) => {
  const [fixedTopPosition, setFixed] = useState(false)
  const modalWrapper = useRef(null)
  const modal = useRef(null)

  const handleClose = useCallback(
    (event: MouseEvent<HTMLDivElement> | any) => {
      if (event && typeof event.stopPropagation === 'function')
        event.stopPropagation()
      if (!preventDefaultClose) dispatch(hideModal())
      onClose()
    },
    [dispatch, onClose, preventDefaultClose]
  )

  const handleStopPropagation = useCallback((event: MouseEvent) => {
    event.stopPropagation()
  }, [])

  useKeyPressEvent('Escape', handleClose)

  useEffect(() => {
    if (typeof window !== 'undefined') onOpen()
    if (!modalWrapper || !modal) return undefined

    const wrapperHeight = pathOr(0, ['current', 'offsetHeight'], modalWrapper)
    const modalHeight = pathOr(0, ['current', 'offsetHeight'], modal)
    setFixed(false)

    if (wrapperHeight - modalHeight <= POSITION_100) setFixed(true)
    return undefined
  }, [onOpen])

  const renderClose = () => (
    <div className={s.close__parent}
      role='presentation'
      onClick={handleClose}>
      <div className={s.close__container}>
        <div className={s.close}>
          <Icon icon='close'
            className={s.icon} />
        </div>
      </div>
    </div>
  )

  return (
    <DisableBodyScroll>
      <div
        className={s.wrapper}
        ref={modalWrapper}
      >
        <div
          role='presentation'
          className={cx(s.modal, {
            [s.modal_fixed_top]: fixedTopPosition && size !== 'auto',
            [s[`modal_size_${size}`]]: !!size,
            [s[`modal_bg_${background}`]]: !!background
          })}
          onClick={handleStopPropagation}
          ref={modal}
        >
          {isCloseButton && renderClose()}
          <div
            className={cx(s.modal__wrapper, {
              [s[`modal__wrapper_padding_${size}`]]: !!size,
              [s[`modal__wrapper_bg_${background}`]]: !!background
            })}
          >
            {!!title && (
              <span
                className={cx(s.modal__title, {
                  [s.modal__title_empty]: !title,
                  [s.modal__title_size_small]: size === 'small'
                })}
              >
                {title}
              </span>
            )}
            {children}
          </div>
        </div>
      </div>
    </DisableBodyScroll>
  )
}

export default memo(Modal)
