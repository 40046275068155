/* eslint-disable complexity */
import R from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import config from 'config'
import objectToQuery from 'helpers/objectToQuery'
import { clearCheckedItems } from 'redux/modules/products'

import { parseFilters } from './selector'

const itemsInView = config.filterItemsInView

const initialState = {
  section: '',
  filters: [],
  favoriteFilters: [],
  hotFilters: [],
  disabled: {},
  checkedItems: {},
  priceRange: {
    min: 0,
    max: 0
  },
  isExpandAll: {},
  count: null,
  url: '',
  query: {},
  onApply: {},
  lastUpdate: '',
  lastValue: null,
  isLoading: false,
  isLoaded: false
}

export {
  filtersSelector,
  favoritesSelector,
  hotFiltersSelector,
  staticFiltersSelector,
  dynamicFiltersSelector,
  productsCountSelector,
  sectionsSelector,
  checkedItemsSelector,
  disabledItemsSelector,
  priceRangeSelector,
  isExpandAllSelector,
  isLoadingSelector
} from './selector'

export const fetch = createAction('productsFilter/FETCH')
export const setFilters = createAction('productsFilter/SET_FILTERS')
export const updateFilters = createAction('productsFilter/UPDATE_FILTERS')
const fetchFailure = createAction('productsFilter/FETCH_FAILURE')

export const setSection = createAction('productsFilter/SET_SECTION')
export const setChecked = createAction('productsFilter/SET_CHECKED')
export const setExpand = createAction('productsFilter/SET_EXPAND')
export const hoistChecked = createAction('productsFilter/HOIST_CHECKED')
export const hoistAll = createAction('productsFilter/HOIST_ALL')
export const setFilter = createAction('productsFilter/SET_PRICE_VALUES')

export const applyFilters = createAction('productsFilter/APPLY_FILTERS')
export const initFilters = createAction('productsFilter/INIT_FILTERS')
export const resetFilters = createAction('productsFilter/RESET_FILTERS')
export const resetPrice = createAction('productsFilter/RESET_PRICE')

const request =
  ({ clientApi }) =>
    ({ url, params, filter }) =>
      clientApi
        .get(`/v3/${url}${objectToQuery({ filter })}`, {
          params: { ...params, contractor_id: clientApi.getContractorId() }
        })
        .then(updateFilters)
        .catch(fetchFailure)

const handleFetch = (state, { url, params, filter }, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), { url, params, filter })
  )

const handleSetFilters = (state, payload) => {
  const data = R.pathOr({}, ['data', 'response'], payload)
  if (!R.prop('AGGREGATIONS', data)) {
    return state
  }
  const count = R.pathOr(0, ['NAV', 'CNT'], data)
  const {
    filters,
    favoriteFilters,
    hotFilters,
    staticFilters,
    dynamicFilters,
    priceRange,
    sections,
    disabled
  } = parseFilters(data)

  return {
    ...state,
    count,
    filters,
    favoriteFilters,
    hotFilters,
    staticFilters,
    dynamicFilters,
    sections,
    priceRange,
    disabled,
    onApply: {},
    isLoading: false,
    isLoaded: true
  }
}

const handleUpdateFilters = (state, payload) => {
  const data = R.pathOr({}, ['data', 'response'], payload)
  const count = R.pathOr(0, ['NAV', 'CNT'], data)
  if (!R.prop('AGGREGATIONS', data)) {
    return {
      ...state,
      count,
      isLoading: false,
      isLoaded: true
    }
  }
  const {
    filters,
    favoriteFilters,
    hotFilters,
    staticFilters,
    dynamicFilters,
    priceRange,
    sections,
    disabled
  } = parseFilters(data)
  const onApply = { priceRange }

  return {
    ...state,
    filters,
    favoriteFilters,
    hotFilters,
    staticFilters,
    dynamicFilters,
    sections,
    priceRange,
    disabled,
    onApply,
    count,
    lastUpdate: '',
    lastValue: null,
    isLoading: false,
    isLoaded: true
  }
}

const handleFetchFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false
})

const handleSetExpand = (state, payload = {}) => ({
  ...state,
  isExpandAll: {
    ...state.isExpandAll,
    ...payload
  }
})

const handleSetSection = (state, section = '') => ({
  ...state,
  section
})

const handleSetChecked = (state, { filterId, itemId = '', checked }) => {
  let checkedFilter = [].concat(R.pathOr([], ['checkedItems', filterId], state))
  if (!checked) {
    checkedFilter = itemId
      ? checkedFilter.filter(item => item.toString() !== itemId.toString())
      : [] // если нет itemId, то удаляем всю группу фильтров
  } else if (checkedFilter.indexOf(itemId.toString()) === -1) {
    checkedFilter.push(itemId.toString())
  }
  const checkedItems = checkedFilter.length
    ? { ...state.checkedItems, [filterId]: checkedFilter.sort() }
    : R.omit([filterId], state.checkedItems)
  return {
    ...state,
    checkedItems,
    count: null,
    lastUpdate: filterId,
    lastValue: checked
  }
}

const handleApplyFilters = (state, payload = {}) => {
  const checkedItems = payload.checkedItems || state.checkedItems
  return loop(
    {
      ...state,
      ...state.onApply,
      onApply: {},
      checkedItems
    },
    Effects.call(clearCheckedItems)
  )
}

const handleSetFilter = (state, { request: uri, ...payload } = {}) => ({
  ...state,
  checkedItems: {
    ...state.checkedItems,
    ...payload
  },
  isLoading: !!uri,
  isLoaded: !uri
})

const handleInitFilters = (state, { url, query }) => ({
  ...state,
  url,
  query
})

const hoister = (state, filterId, filterObj) => {
  const filter = filterObj || R.find(R.propEq('id', filterId), state.filters)
  const checked = R.pathOr([], ['checkedItems', filterId], state)
  const isChecked = item => R.contains(item.id, checked)
  return R.concat(
    R.filter(isChecked, filter.items),
    R.filter(R.complement(isChecked), filter.items)
  )
}

const handleHoistChecked = (state, filterId) => {
  let index
  const filter = state.filters.find((currentFilter, currentIndex) => {
    index = currentIndex
    return currentFilter.id === filterId
  })

  return {
    ...state,
    filters: [
      ...state.filters.slice(0, index),
      {
        ...filter,
        items: hoister(state, filterId)
      },
      ...state.filters.slice(index + 1)
    ]
  }
}

const handleHoistAll = state => {
  const { filters } = state
  const newFilters = filters.map(filter =>
    filter.items.length <= itemsInView
      ? filter
      : {
        ...filter,
        items: hoister(state, filter.id, filter)
      }
  )

  return {
    ...state,
    filters: newFilters
  }
}

const handleResetFilters = state => ({
  ...state,
  ...state.initialFilters,
  section: '',
  filtersDisabled: {},
  checkedItems: {}
})

const handleResetPrice = state => {
  const checkedItemsFromState = R.propOr({}, 'checkedItems', state)
  return {
    ...state,
    checkedItems: R.omit(['MAX_PRICE', 'MIN_PRICE'], checkedItemsFromState)
  }
}

export default createReducer(on => {
  on(fetch, handleFetch)
  on(setFilters, handleSetFilters)
  on(updateFilters, handleUpdateFilters)
  on(fetchFailure, handleFetchFailure)

  on(setExpand, handleSetExpand)
  on(setSection, handleSetSection)
  on(setChecked, handleSetChecked)
  on(hoistChecked, handleHoistChecked)
  on(hoistAll, handleHoistAll)
  on(setFilter, handleSetFilter)

  on(applyFilters, handleApplyFilters)
  on(initFilters, handleInitFilters)
  on(resetFilters, handleResetFilters)
  on(resetPrice, handleResetPrice)
}, initialState)
