/* eslint-disable complexity */
import {
  propOr,
  prop,
  values,
  compose,
  reduce,
  toLower,
  mapObjIndexed,
  defaultTo,
  pluck,
  pickAll,
  length,
  keys,
  map,
  includes,
  pathOr,
  filter,
  equals,
  toString,
  dec,
  difference
} from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'

const DEPTH_2 = 2

// prepare filters
const getItemsData = createSelector(
  item => item,
  item =>
    prop('CODE', item) !== 'PRICE'
      ? compose(
        reduce(
          (acc, val) => ({
            items: acc.items.concat({
              id: prop('ID', val),
              title: prop('VALUE', val),
              count: prop('CNT', val),
              depth: Number(propOr(0, 'DEPTH', val))
            }),
            itemsId: acc.itemsId.concat(prop('ID', val))
          }),
          { items: [], itemsId: [] }
        ),
        values,
        propOr([], 'VALUES')
      )(item)
      : {
        items: compose(
          renameKeys(toLower),
          mapObjIndexed(val => defaultTo(0, val)),
          pluck('VALUE'),
          pickAll(['MAX', 'MIN']),
          propOr({}, 'VALUES')
        )(item),
        itemsId: [],
        totalLength: compose(length, keys, propOr({}, 'VALUES'))(item)
      }
)

export const parseFilters = createSelector(
  data => data,
  data => {
    const filterData = propOr([], 'AGGREGATIONS', data)
    const favoriteFilterData = propOr([], 'FAVORITE', data)
    const hotFilterData = propOr([], 'HOT', data)

    if (!filterData) {
      return {}
    }

    const sections = propOr([], 'SECTIONS', data)

    const filters = map(
      item => ({
        title: defaultTo('Каталог', prop('TITLE', item)),
        primary: includes(prop('CODE', item), ['OFFERS', 'MARKS']),
        id: prop('CODE', item),
        type: prop('TYPE', item),
        isExpand: prop('EXPAND', item),
        totalLength: length(propOr([], 'VALUES', item)),
        ...getItemsData(item)
      }),
      filterData
    )

    // поднять вверх приоритетные фильтры в заданном порядке
    const firstItemsOrder = ['OFFERS', 'TM', 'REMAINS', 'VID_TOVARA', 'PRICE', 'MARKS']

    const filteredItems = filters.sort((a, b) => {
      const i1 = firstItemsOrder.indexOf(a.id)
      const i2 = firstItemsOrder.indexOf(b.id)
      
      if (i1 < 0) return 1
      if (i2 < 0) return -1
      return i1 - i2
    })

    // для модального окна - деление на статические (приоритетные) и динамические (остальные)
    const staticFilters = filteredItems.filter(item => includes(prop('id', item), firstItemsOrder))
    const dynamicFilters = difference(filteredItems, staticFilters)

    const favoriteFilters = map(
      item => ({
        code: prop('CODE', item),
        isExpand: prop('EXPAND', item),
        isFavorite: prop('FAVORITE', item),
        title: propOr('', 'TITLE', item),
        totalLength: length(propOr([], 'VALUES', item)),
        ...getItemsData(item)
      }),
      favoriteFilterData
    )

    const hotFilters = map(
      item => ({
        code: propOr('', 'CODE', item),
        id: String(propOr('', 'ID', item)),
        title: propOr('', 'TITLE', item),
        value: propOr('', 'VALUE', item)
      }),
      hotFilterData
    )

    const disabled = reduce((acc, property) => {
      acc[pathOr('', ['CODE'], property)] = compose(
        map(item => prop('ID', item)),
        filter(item => propOr(false, 'DISABLED', item) === true),
        pathOr([], ['VALUES'])
      )(property)
      return acc
    }, {})(filterData)

    const priceRange = compose(
      renameKeys(toLower),
      mapObjIndexed(val => defaultTo(0, val)),
      pluck('VALUE'),
      pickAll(['MAX', 'MIN']),
      pathOr([], [0, 'VALUES']),
      filter(item => prop('CODE', item) === 'PRICE')
    )(filterData)

    return {
      filters: filteredItems,
      favoriteFilters,
      hotFilters,
      staticFilters,
      dynamicFilters,
      priceRange,
      sections,
      disabled
    }
  }
)

// selectors from state
export const filtersSelector = createSelector(
  state => state,
  state => propOr([], 'filters', state)
)

export const favoritesSelector = createSelector(
  state => state,
  state => propOr([], 'favoriteFilters', state)
)

export const hotFiltersSelector = createSelector(
  state => state,
  state => propOr([], 'hotFilters', state)
)

export const staticFiltersSelector = createSelector(
  state => state,
  state => propOr([], 'staticFilters', state)
)

export const dynamicFiltersSelector = createSelector(
  state => state,
  state => propOr([], 'dynamicFilters', state)
)

export const productsCountSelector = createSelector(
  state => state,
  state => propOr(0, 'count', state)
)

export const sectionsSelector = createSelector(
  state => pathOr([], ['productsFilter', 'sections'], state),
  state => propOr('', 'section', state),
  (sectionsList, activeSection) => {
    const maxDepth = Math.max(...sectionsList.map(item => prop('DEPTH', item)))
    const isThreeLevelsView = !!activeSection && maxDepth > DEPTH_2
    const sections = map(item => {
      const itemDepth = prop('DEPTH', item)
      if (isThreeLevelsView && itemDepth < maxDepth - DEPTH_2) {
        return null
      }
      const isSectionActive =
        !!activeSection &&
        compose(equals(activeSection), toString, prop('ID'))(item)
      return {
        ...item,
        isSectionActive,
        isTopLevelOpened:
          isThreeLevelsView &&
          !isSectionActive &&
          itemDepth === maxDepth - DEPTH_2,
        isPreviousLevelOpened:
          isThreeLevelsView && !isSectionActive && itemDepth === dec(maxDepth)
      }
    })(sectionsList)
    return {
      items: sections,
      maxDepth
    }
  }
)

export const checkedItemsSelector = createSelector(
  state => state,
  state => propOr({}, 'checkedItems', state)
)

export const disabledItemsSelector = createSelector(
  state => state,
  state => propOr({}, 'disabled', state)
)

export const priceRangeSelector = createSelector(
  state => state,
  state => propOr({}, 'priceRange', state)
)

export const isExpandAllSelector = createSelector(
  state => state,
  state => propOr({}, 'isExpandAll', state)
)

export const isLoadingSelector = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)
