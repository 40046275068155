import React from 'react'

import { object, array, bool, func, number } from 'prop-types'
import { propOr } from 'ramda'

import BottomCabinetMenu from 'components/LeftMenu/BottomCabinetMenu'
import UserManagers from 'components/UserManagers'
import UserPanel from 'components/UserPanel'
import { ContractorChangeButton, ContractorInfo } from 'containers/ContractorInfo'

import styles from './LeftMenu.scss'

const LeftMenu = ({
  menuItems = [],
  menuCabinetBottom = [],
  managers = [],
  isTempUser = false,
  isMobileMenu = false,
  onSignOut,
  favoriteCount = 0,
  purchasedCount = 0,
  compareCount = 0,
  location = {}
}) => {
  return (
    <div className={styles.cabinet}>
      {isMobileMenu && (
        <div className={styles.cabinet__contractor}>
          <ContractorInfo />
          <ContractorChangeButton />
        </div>
      )}
      <div className={styles.cabinet__info}>
        <div className={styles.cabinet__menu}>
          <UserPanel
            pathname={propOr('/', 'pathname', location)}
            menuItems={menuItems}
            isTempUser={isTempUser}
            onSignOut={onSignOut}
          />
          <BottomCabinetMenu
            menuCabinetBottom={menuCabinetBottom}
            favoriteCount={favoriteCount}
            purchasedCount={purchasedCount}
            compareCount={compareCount}
          />
        </div>
        <div className={styles.cabinet__manager}>
          <div className={styles.cabinetBlock}>
            <UserManagers managers={managers} />
          </div>
        </div>
      </div>
    </div>
  )
}

LeftMenu.propTypes = {
  location: object,
  favoriteCount: number,
  purchasedCount: number,
  compareCount: number,
  managers: array,
  isTempUser: bool,
  isMobileMenu: bool,
  menuItems: array,
  menuCabinetBottom: array,
  onSignOut: func
}

LeftMenu.defaultProps = {
  managers: [],
  isTempUser: false
}

export default LeftMenu
